type NationalHolidaysType = {
  [key: string]: string[];
};

export const nationalHolidays: NationalHolidaysType = {
  Colombia: [
    // Source: https://www.municipio.com.co/dias-festivos-2022.html
    // 2022
    '2022-01-01',
    '2022-01-10',
    '2022-03-21',
    '2022-04-14', // Depends on day of week
    '2022-04-15', // Depends on day of week
    '2022-05-01',
    '2022-05-30',
    '2022-06-20',
    '2022-06-27',
    '2022-07-04',
    '2022-07-20',
    '2022-08-07',
    '2022-08-15',
    '2022-10-17',
    '2022-11-07',
    '2022-11-14',
    '2022-12-08',
    '2022-12-25',
    // 2023
    '2023-01-01',
    '2023-09-01',
    '2023-03-20',
    '2023-04-06',
    '2023-04-07',
    '2023-05-01',
    '2023-05-22',
    '2023-06-12',
    '2023-06-19',
    '2023-07-03',
    '2023-07-20',
    '2023-08-07',
    '2023-08-21',
    '2023-10-16',
    '2023-11-06',
    '2023-11-13',
    '2023-12-08',
    '2023-12-25',
    //Source: https://calendariopro.com/calendario-colombia-2024/
    // 2024
    '2024-01-01', // Año nuevo
    '2024-01-08', // Epifania
    '2024-03-25', // San José
    '2024-03-28', // Jueves Santo
    '2024-03-29', // Viernes Santo
    '2024-05-01', // Labor Day
    '2024-05-13', // Ascención
    '2024-06-03', // Corpus Christi
    '2024-06-10', // Sdo. Corazón
    '2024-07-01', // Ss. Pedro y Pablo
    '2024-07-20', // Independencia
    '2024-08-07', // Batalla de Boyacá
    '2024-08-19', // Suposición
    '2024-10-14', // Día de la Raza
    '2024-11-04', // Todos los Santos
    '2024-11-11', // CARTAGENA
    '2024-12-08', // Inmaculada Concepción
    '2024-12-25', // Navidad
    // 2025
    '2025-01-01', // New Year's Day
    '2025-01-06', // Epiphany
    '2025-03-24', // Saint Joseph's Day
    '2025-04-13', // Palm Sunday
    '2025-04-17', // Maundy Thursday
    '2025-04-18', // Good Friday
    '2025-05-01', // Labor Day
    '2025-06-02', // Corpus Christi
    '2025-06-23', // Sacred Heart
    '2025-06-30', // Saints Peter and Paul
    '2025-07-20', // Independence Day
    '2025-08-07', // Battle of Boyacá
    '2025-08-18', // Assumption of Mary
    '2025-10-13', // Columbus Day
    '2025-11-03', // All Saints' Day
    '2025-11-17', // Independence of Cartagena
    '2025-12-08', // Immaculate Conception
    '2025-12-25', // Christmas Day
  ],
  Peru: [
    // Source www.gob.pe/feriados
    // 2022
    '2022-01-01',
    '2022-04-14',
    '2022-04-15', // Depends on day of week
    '2022-04-17',
    '2022-05-01',
    '2022-06-29',
    '2022-07-28',
    '2022-07-29',
    '2022-08-30',
    '2022-10-08',
    '2022-10-31',
    '2022-11-01',
    '2022-12-08',
    '2022-12-25',
    // 2023
    '2023-01-01',
    '2023-04-14',
    '2023-04-15', // Depends on day of week
    '2023-04-28',
    '2023-05-01',
    '2023-06-29',
    '2023-06-30',
    '2023-07-28',
    '2023-08-30',
    '2023-10-09',
    '2023-11-01',
    '2023-12-08',
    '2023-12-25',
    //Source: Google Gemini
    // 2024

    '2024-01-01', // Año Nuevo (Festivo)
    '2024-03-28', // Jueves Santo (Festivo)
    '2024-03-29', // Viernes Santo (Festivo)
    '2024-04-02', // Lunes Santo (No Laborable)
    '2024-05-01', // Día del Trabajo (Festivo)
    '2024-06-07', // Batalla de Arica y Día de la Bandera (Festivo)
    '2024-06-08', // Corpus Christi (No Laborable)
    '2024-06-29', // San Pedro y San Pablo (Festivo)
    '2024-07-23', // Día de la Fuerza Aérea del Perú (Festivo)
    '2024-07-28', // Fiestas Patrias (Festivo)
    '2024-07-29', // Fiestas Patrias (Festivo)
    '2024-08-06', // Batalla de Junín (Festivo)
    '2024-08-15', // Asunción de la Virgen (No Laborable)
    '2024-08-30', // Santa Rosa de Lima (Festivo)
    '2024-10-08', // Combate de Angamos y Día de la Marina de Guerra del Perú (Festivo)
    '2024-11-01', // Día de Todos los Santos (No Laborable)
    '2024-12-08', // Inmaculada Concepción (No Laborable)
    '2024-12-25', // Navidad (Festivo)
    // 2025
    '2025-01-01', // New Year's Day
    '2025-04-17', // Maundy Thursday
    '2025-04-18', // Good Friday
    '2025-05-01', // Labor Day
    '2025-06-29', // Saints Peter and Paul
    '2025-07-28', // Independence Day
    '2025-07-29', // Independence Day Holiday
    '2025-08-30', // Santa Rosa de Lima
    '2025-10-08', // Battle of Angamos
    '2025-11-01', // All Saints' Day
    '2025-12-08', // Immaculate Conception
    '2025-12-25', // Christmas Day
  ],
  Guatemala: [
    // Source https://www.prensalibre.com/guatemala/comunitario/feriados-y-asuetos-2022-en-guatemala-como-seran-los-descansos-breaking/
    // 2022
    '2022-01-01',
    '2022-04-14', // Depends on day of week
    '2022-04-15', // Depends on day of week
    '2022-04-16',
    '2022-05-01',
    '2022-07-04',
    '2022-09-15',
    '2022-10-20',
    '2022-11-01',
    '2022-12-24',
    '2022-12-25',
    // 2023
    '2023-01-01',
    '2023-04-14', // Depends on day of week
    '2023-04-15', // Depends on day of week
    '2023-04-16',
    '2023-05-01',
    '2023-07-04',
    '2023-09-15',
    '2023-10-20',
    '2023-11-01',
    '2023-12-24',
    '2023-12-25',
    // 2024
    '2024-01-01',
    '2024-12-25',
  ],
  Nicaragua: [
    // Source https://www.municipio.co.ni/dias-festivos-2022.html
    // 2022
    '2022-01-01',
    '2022-04-14', // Depends on day of week
    '2022-04-15', // Depends on day of week
    '2022-05-02',
    '2022-05-30',
    '2022-07-09',
    '2022-07-19',
    '2022-09-14',
    '2022-09-15',
    '2022-11-02',
    '2022-12-08',
    '2022-12-25',
    // 2023
    '2023-01-01',
    '2023-04-14', // Depends on day of week
    '2023-04-15', // Depends on day of week
    '2023-07-19',
    '2023-09-14',
    '2023-09-15',
    '2023-12-08',
    '2023-12-25',
    // 2024
    // Source: Google Gemini
    '2024-01-01', // Año Nuevo (Festivo)
    '2024-04-01', // Domingo de Ramos (No Laborable)
    '2024-04-02', // Lunes Santo (No Laborable)
    '2024-04-05', // Jueves Santo (Festivo)
    '2024-04-06', // Viernes Santo (Festivo)
    '2024-05-01', // Día del Trabajo (Festivo)
    '2024-05-04', // Batalla de San Jacinto (Festivo)
    '2024-08-15', // Asunción de la Virgen (No Laborable)
    '2024-09-14', // Independencia de Centroamérica (Festivo)
    '2024-12-08', // Inmaculada Concepción (No Laborable)
    '2024-12-25', // Navidad (Festivo)
    // 2025
    '2025-01-01', // New Year's Day
    '2025-04-17', // Maundy Thursday
    '2025-04-18', // Good Friday
    '2025-05-01', // Labor Day
    '2025-07-19', // Liberation Day
    '2025-09-14', // Battle of San Jacinto
    '2025-09-15', // Independence Day
    '2025-12-08', // Immaculate Conception
    '2025-12-25', // Christmas Day
  ],
  Venezuela: [
    // 2022
    '2022-01-01',
    '2022-02-28',
    '2022-04-14',
    '2022-04-15',
    '2022-04-19',
    '2022-05-01',
    '2022-06-24',
    '2022-07-05',
    '2022-07-24',
    '2022-08-15',
    '2022-10-24',
    '2022-11-01',
    '2022-12-24',
    '2022-12-25',
    '2022-12-31',
    // 2023
    '2023-01-01',
    '2023-02-28',
    '2023-04-14',
    '2023-04-15',
    '2023-04-19',
    '2023-05-01',
    '2023-06-24',
    '2023-07-05',
    '2023-07-24',
    '2023-08-15',
    '2023-10-24',
    '2023-11-01',
    '2023-12-24',
    '2023-12-25',
    '2023-12-31',
    // 2024
    '2024-01-01',
    '2024-12-25',
    // 2025
    '2025-01-01', // New Year's Day
    '2025-02-24', // Carnival Monday
    '2025-02-25', // Carnival Tuesday
    '2025-04-17', // Maundy Thursday
    '2025-04-18', // Good Friday
    '2025-04-19', // Declaration of Independence
    '2025-05-01', // Labor Day
    '2025-06-24', // Battle of Carabobo
    '2025-07-05', // Independence Day
    '2025-07-24', // Simón Bolívar's Birthday
    '2025-10-12', // Indigenous Resistance Day
    '2025-12-24', // Christmas Eve
    '2025-12-25', // Christmas Day
    '2025-12-31', // New Year's Eve
  ],
  Ecuador: [
    // 2022
    '2022-01-01',
    '2022-02-28',
    '2022-04-15',
    '2022-04-15',
    '2022-05-01',
    '2022-08-10',
    '2022-08-12',
    '2022-10-09',
    '2022-11-02',
    '2022-11-03',
    '2022-12-25',
    // 2023
    '2023-01-01',
    '2023-02-28',
    '2023-04-15',
    '2023-04-15',
    '2023-05-01',
    '2023-05-26',
    '2023-08-11',
    '2023-10-09',
    '2023-11-02',
    '2023-11-03',
    '2023-12-25',
    // 2024
    '2024-01-01',
    '2024-12-25',
  ],
  USA: [
    // 2022
    '2022-01-16',
    '2022-02-20',
    '2022-05-29',
    '2022-07-04',
    '2022-09-04',
    '2022-10-09',
    '2022-11-10',
    '2022-11-23',
    '2022-12-25',
    // 2023
    '2023-01-16',
    '2023-02-20',
    '2023-05-29',
    '2023-07-04',
    '2023-09-04',
    '2023-10-09',
    '2023-11-11',
    '2023-11-23',
    '2023-12-25',
    // 2024
    '2024-01-16',
    '2024-12-25',
  ],
  Bolivia: [
    //2023
    '2023-01-01',
    '2023-01-22',
    '2023-02-20',
    '2023-02-21',
    '2023-04-06',
    '2023-04-07',
    '2023-05-01',
    '2023-06-08',
    '2023-06-21',
    '2023-08-06',
    '2023-11-02',
    '2023-12-25',
    // Source: Google Gemini
    // 2024
    '2024-01-01', // Año Nuevo (Festivo)
    '2024-02-06', // Día del Estado Plurinacional de Bolivia (Festivo)
    '2024-04-01', // Domingo de Ramos (No Laborable)
    '2024-05-01', // Día del Trabajo (Festivo)
    '2024-05-24', // Día de la Madre (No Laborable)
    '2024-06-06', // Corpus Christi (Festivo)
    '2024-06-24', // Año Nuevo Andino (Festivo)
    '2024-08-06', // Día de la Independencia (Festivo)
    '2024-08-15', // Asunción de la Virgen (No Laborable)
    '2024-10-12', // Día de la Raza (Festivo)
    '2024-10-18', // Día de la Patria (No Laborable)
    '2024-11-01', // Todos los Santos (Festivo)
    '2024-12-08', // Inmaculada Concepción (No Laborable)
    '2024-12-25', // Navidad (Festivo)
    // 2025
    '2025-01-01', // New Year's Day
    '2025-02-24', // Carnival Monday
    '2025-02-25', // Carnival Tuesday
    '2025-04-18', // Good Friday
    '2025-05-01', // Labor Day
    '2025-06-21', // Aymara New Year
    '2025-08-06', // Independence Day
    '2025-11-02', // All Souls' Day
    '2025-12-25', // Christmas Day
  ],
};
